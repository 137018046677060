import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import thunk from 'redux-thunk';

import reducers from '@ttstr/reducers';

const middlewares: Middleware[] = [thunk];

const composeEnhanced =
  process.env.NODE_ENV === 'production' ? compose : require('redux-devtools-extension').composeWithDevTools;

/**
 * Configures the global redux store.
 */
export function configureStore(initialState?: Record<string, unknown>) {
  const store = createStore(reducers, initialState, composeEnhanced(applyMiddleware(...middlewares)));
  return store;
}
