import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { AppState } from '@ttstr/reducers';
import { useShallowEqualSelector } from '@ttstr/utils';
import Container from 'reactstrap/lib/Container';

import { CheckoutWrapper } from '@ttstr/components/Checkout';

declare global {
  interface Window {
    gtag: any | undefined;
    fbq: any | undefined;
  }
}

const Checkout: React.FC = () => {
  const { t } = useTranslation();
  const price = useShallowEqualSelector(mapStateToProps);
  const history = useHistory();

  // for facebook Purchase Event
  React.useEffect(() => {
    if (history.location.pathname === '/checkout/confirm') {
      const button = document.getElementById('confirm-purchase-button');
      if (button) {
        const func1 = function () {
          if ('fbq' in window) {
            window.fbq('track', 'Purchase', { value: price, currency: 'EUR' });
          }
        };
        button.addEventListener('click', func1);
      }
    }
  }, [history.location.pathname]);

  return (
    <article className="mt-5">
      <Container>
        <Helmet>
          <title>{t('CHECKOUT.TITLE')}</title>
          <script dangerouslySetInnerHTML={{ __html: `fbq('track', 'InitiateCheckout');` }} />
          <body className="checkout" />
        </Helmet>
        <h1 className="text-center mb-4">{t('CHECKOUT.TITLE')}</h1>
      </Container>
      <CheckoutWrapper />
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  return state.Cart.cart?.price_after_coupons;
};

export default React.memo(Checkout);
