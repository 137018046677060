import './polyfills';

import React from 'react';

import ReactDOM from 'react-dom';

import * as serviceWorker from '@ttstr/components/ServiceWorker/ServiceWorker';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();
