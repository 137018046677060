import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Fade from 'reactstrap/lib/Fade';

import { receiveProduct } from '@ttstr/actions/products';
import { Ticket } from '@ttstr/api/products';
import { LoadingSpinner, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { getProductById } from '@ttstr/reducers/productDetails';
import { useActions, useShallowEqualSelector, useMounted } from '@ttstr/utils';

import ProductDetail from './ProductDetail';
import TicketDetail from './TicketDetail';
import NotFound from './NotFound';

interface RouteParams {
  readonly id: string;
}

const mapDispatchToProps = {
  receiveProduct,
};

const AbstractProductDetail: React.FC = () => {
  const isMounted = useMounted();
  const { t } = useTranslation();
  const { language } = useIntl();
  const params = useParams<RouteParams>();
  const [initialized, setInitialized] = React.useState(false);
  const { receiveProduct } = useActions(mapDispatchToProps);
  const { product, loading } = useShallowEqualSelector((state: AppState) => {
    const { loading } = state.ProductDetails;
    const product = getProductById(state, Number(params.id));
    return {
      loading,
      product,
    };
  });

  React.useEffect(() => {
    (async () => {
      try {
        setInitialized(false);
        await receiveProduct(Number(params.id));
      } finally {
        if (isMounted.current) setInitialized(true);
      }
    })();
  }, [params.id, language]);

  if (!initialized || (!product && loading)) {
    return <LoadingSpinner label={t(`LOADING.PRODUCT`)} />;
  }

  if (initialized && !product) {
    return <NotFound />;
  }

  switch (product.type) {
    case 'Ticket':
      return (
        <Fade>
          <TicketDetail product={product as Ticket} />
        </Fade>
      );
    default:
      return (
        <Fade>
          <ProductDetail product={product} />
        </Fade>
      );
  }
};

export default React.memo(AbstractProductDetail);
