import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';

import { Container } from '@ttstr/components';

const CartExpired: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="my-5">
      <Container className="text-center">
        <Helmet>
          <title>{t(`CART.EXPIRED_OR_EMPTY.TITLE`)}</title>
        </Helmet>
        <h1 className="mb-5">{t(`CART.EXPIRED.TITLE`)}</h1>
        <p className="text-muted">{t('CART.EXPIRED_OR_EMPTY.DESCRIPTION')}</p>
        <Button tag={Link} color="primary" to="/">
          {t('CART.EXPIRED_OR_EMPTY.START_SHOPPING')}
        </Button>
      </Container>
    </article>
  );
};

export default React.memo(CartExpired);
