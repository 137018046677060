import React from 'react';

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Container } from '@ttstr/components';
import ConversionStatus from '@ttstr/components/Checkout/ConversionStatus';

interface OrderRouteParams {
  cart_token: string;
}

const Conversion: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<OrderRouteParams>();

  return (
    <article className="my-5">
      <Container className="text-center">
        <Helmet>
          <title>{t('CART.CONVERSION.TITLE')}</title>
        </Helmet>

        <h1 className="mb-4">{t('CART.CONVERSION.TITLE')}</h1>

        <ConversionStatus cartToken={params.cart_token} />
      </Container>
    </article>
  );
};

export default React.memo(Conversion);
