import React from 'react';

import { useTranslation } from 'react-i18next';

import { requestLogout } from '@ttstr/actions/customer';
import { Container, LoadingSpinner } from '@ttstr/components';
import { useAuth } from '@ttstr/components/Auth/AuthContext';
import { useActions } from '@ttstr/utils';

const mapDispatchToProps = {
  requestLogout,
};

const Logout: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const { requestLogout } = useActions(mapDispatchToProps);
  const { t } = useTranslation();
  const { loggingOut, loggedIn } = useAuth();

  React.useEffect(() => {
    try {
      setLoading(true);
      requestLogout();
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading || loggingOut) return <LoadingSpinner label={t(`LOGOUT.IN_PROGRESS`)} />;

  return (
    <article className="my-5">
      <Container>
        {!loggedIn ? (
          <h1 className="text-center mb-4">
            {t('LOGOUT.SUCCESS')}
            <small className="mt-2">
              <a href="/" className="btn btn-primary">
                {t('NAVIGATION.GOTO_HOME')}
              </a>
            </small>
          </h1>
        ) : (
          <span className="text-danger">{t('LOGOUT.ERROR')}</span>
        )}
      </Container>
    </article>
  );
};

export default React.memo(Logout);
