import React from 'react';

import { useTranslation } from 'react-i18next';

const LandingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-4 text-center">
      <legend>{t('CUSTOMER.LANDING_PAGE.NO_NEWS')}</legend>
    </div>
  );
};

export default React.memo(LandingPage);
