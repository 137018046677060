import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Container from 'reactstrap/lib/Container';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="my-5">
      <Container className="text-center">
        <Helmet>
          <title>{t(`NOT_FOUND.TITLE`)}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <h1>{t(`NOT_FOUND.TITLE`)}</h1>
        <p className="emoji-thumb">
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <span role="img" lang="en" aria-label="screaming cat emoji">
            🙀
          </span>
        </p>
        <p>{t(`NOT_FOUND.LEAD`)}</p>
        <Button tag={Link} color="primary" to="/">
          {t('CART.EXPIRED_OR_EMPTY.START_SHOPPING')}
        </Button>
      </Container>
    </article>
  );
};

export default React.memo(NotFound);
