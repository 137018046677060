import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { requestConfirmCustomer } from '@ttstr/actions/customer';
import { Container, LoadingSpinner } from '@ttstr/components';
import { useActions } from '@ttstr/utils';

interface RouteParams {
  confirmationToken: string;
}

const CustomerConfirm: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const params = useParams<RouteParams>();
  const { requestConfirmCustomer } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await requestConfirmCustomer(params.confirmationToken);
        setConfirmed(true);
      } catch (error) {
        setConfirmed(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [params.confirmationToken]);

  return (
    <article className="my-5">
      <Container className="text-center ">
        <Helmet>
          <title>{t(`CUSTOMER.CONFIRM.TITLE`)}</title>
        </Helmet>
        <h1 className="mb-4">{t(`CUSTOMER.CONFIRM.TITLE`)}</h1>
        {loading && <LoadingSpinner label={t('CUSTOMER.CONFIRM.CONFIRMING')} />}
        {!loading &&
          (confirmed ? (
            <p>{t('CUSTOMER.CONFIRM.CONFIRMED')}</p>
          ) : (
            <p className="text-danger">{t('CUSTOMER.CONFIRM.ERROR')}</p>
          ))}
      </Container>
    </article>
  );
};

const mapDispatchToProps = {
  requestConfirmCustomer,
};

export default React.memo(CustomerConfirm);
