import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { loadCart } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';

import CartListing from '@ttstr/components/ShoppingCart/CartListing';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const Cart: React.FC = () => {
  const { t } = useTranslation();
  const { cartReceived } = useShallowEqualSelector(mapStateToProps);
  const { loadCart } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    if (!cartReceived) loadCart();
  }, [cartReceived]);

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>{t('CART.TITLE')}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t('CART.TITLE')}</h1>
        <CartListing />
      </Container>
    </article>
  );
};

function mapStateToProps(state: AppState) {
  const { cart, loading } = state.Cart;

  return {
    cartReceived: Boolean(cart),
    loading,
  };
}

const mapDispatchToProps = {
  loadCart,
};

export default React.memo(Cart);
