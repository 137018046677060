import queryString from 'querystring';

import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';

import { Container, useAuth } from '@ttstr/components';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { loggedIn, setShowLogin } = useAuth();
  const location = useLocation();
  const qs = React.useMemo(() => {
    return queryString.parse(location.search.substring(1));
  }, [location.search]);
  const wasKicked = Boolean(qs.success);

  React.useEffect(() => {
    if (!wasKicked) setShowLogin(true);
  }, [wasKicked]);

  if (loggedIn && typeof qs.success === 'string') return <Redirect to={qs.success} />;

  if (!wasKicked) return null;

  return (
    <article className="mt-5">
      <Container className="text-center">
        <Helmet>
          <title>{t('CUSTOMER.LOGIN.TITLE')}</title>
        </Helmet>
        <p>{t('CUSTOMER.LOGIN.DESCRIPTION')}</p>
        <Button type="button" color="primary" onClick={() => setShowLogin(true)}>
          {t('CUSTOMER.LOGIN_NOW')}
        </Button>
      </Container>
    </article>
  );
};

export default React.memo(Login);
