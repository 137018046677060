import queryString from 'querystring';

import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Container from 'reactstrap/lib/Container';

import { paymentAborted } from '@ttstr/api';
import { LoadingSpinner } from '@ttstr/components';

interface RouteParams {
  contractor: string; // stripe, paypal, sofort ...
}

const PaymentDone: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const history = useHistory();
  const { contractor } = useParams<RouteParams>();

  const { orderId, xToken, token } = React.useMemo(() => {
    const qs = queryString.parse(location.search.substring(1));
    return {
      orderId: typeof qs.order_id === 'string' ? qs.order_id : null,
      xToken: typeof qs.xtoken === 'string' ? qs.xtoken : null,
      token: typeof qs.token === 'string' ? qs.token : null,
    };
  }, [location.search]);

  React.useEffect(() => {
    (async () => {
      if (typeof orderId !== 'string' || typeof xToken !== 'string') return;

      try {
        setLoading(true);
        const { order_id, simple_token } = await paymentAborted(contractor, orderId, xToken, {
          token,
        });
        history.push(`/orders/${order_id}/xtoken/${encodeURIComponent(simple_token)}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [contractor, orderId, xToken]);

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>{t('PAYMENT.ABORTED.TITLE')}</title>
        </Helmet>
        <h1 className="text-center mb-4">{t('PAYMENT.ABORTED.TITLE')}</h1>
        {loading && <LoadingSpinner />}
      </Container>
    </article>
  );
};

export default React.memo(PaymentDone);
