import React from 'react';

import { useParams } from 'react-router-dom';

import OrderDetails from '@ttstr/components/Order/OrderDetails';

interface RouteParams {
  orderId: string;
}

const OrderDetail: React.FC = () => {
  const params = useParams<RouteParams>();

  return <OrderDetails orderId={params.orderId} />;
};

export default React.memo(OrderDetail);
