import React from 'react';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import CustomerUpdate from '@ttstr/components/Customer/CustomerUpdate';

const Account: React.FC = () => {
  return (
    <Row>
      <Col lg={{ size: 6, offset: 3 }}>
        <CustomerUpdate />
      </Col>
    </Row>
  );
};

export default React.memo(Account);
